import { http } from '../plugin/http2-common';

const BASE_URL = '/api/package'

class SurveyService {

  getAll(countryCode) {
    return http.get(`${BASE_URL}`, {
      params: {
        countryCode
      }
    });
  }

  getById(packageId) {
    return http.get(`${BASE_URL}/${packageId}`);
  }

  getByDesc(searchText) {
    return http.get(`${BASE_URL}/desc`, {
      params: {
        searchText
      }
    });
  }

  create(data) {
    return http.post(`${BASE_URL}`, data);
  }

  duplicate(id) {
    return http.post(`${BASE_URL}/${id}`);
  }

  publish(id) {
    return http.post(`${BASE_URL}/publish/${id}`);
  }

  update(id, data) {
    return http.put(`${BASE_URL}/${id}`, data);
  }

  remove(id) {
    return http.delete(`${BASE_URL}/${id}`);
  }
  
}

export default new SurveyService();