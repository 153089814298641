import { http } from "../plugin/http2-common";

const BASE_URL = "/country-platform";

class PlatformService {
  getAll() {
    return http.get(`${BASE_URL}`);
  }

  upsert(data) {
    data = data
      .filter((i) => i.countryCode && i.countryName)
      .map((i) => {
        return {
          countryCode: i.countryCode,
          countryName: i.countryName,
          lensSelectorAccess: i.lensSelectorAccess,
          emailCollectionAccess: i.emailCollectionAccess,
          showPrivacy: i.showPrivacy,
          removeSurvey: i.removeSurvey,
          storeEmail: i.storeEmail,
        };
      });
    return http.post(`${BASE_URL}/upsert`, data);
  }

  delete(codes) {
    return http.post(`${BASE_URL}/delete`, { codes });
  }
}

export default new PlatformService();
