<template>
  <div class="add">
    <div class="d-flex justify-content-between mb-4">
      <div class="title">
        <div>
          <strong>Add Package</strong>
        </div>
        <div>Assign only 1 language per 1 package</div>
      </div>
      <div>
        <button
          type="buton"
          class="btn btn-outline-primary btn-cus"
          @click="cancel"
        >
          Cancel
        </button>
      </div>
    </div>
    <div class="form-group row">
      <label for="description" class="col-sm-2 col-form-label">Description</label>
      <div class="col-sm-10">
        <textarea-autosize
          v-model="packageInfo.description"
          class="form-control"
          rows="1"
          placeholder="description"
        />
        <div v-if="errors.description" class="text-danger">
          This field is required!
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label for="password" class="col-sm-2 col-form-label">Password</label>
      <div class="col-sm-4">
        <input
          v-model="password"
          type="text"
          class="form-control"
          placeholder="Password"
          @change="changePassword"
        />
        <div v-if="errors.password" class="text-danger">
          This field is required!
        </div>
      </div>
    </div>
    <fieldset class="form-group">
      <div class="row">
        <legend class="col-form-label col-sm-2 pt-0">
          Access to lens selector
        </legend>
        <div class="col-sm-10">
          <div class="form-check form-check-inline">
            <input
              id="choose-yes"
              v-model="packageInfo.accessFlag"
              class="form-check-input"
              type="radio"
              name="yes"
              :disabled="!packageInfo.lensSelectorAccess"
              :value="true"
              @click="onChangeAccessFlag(true)"
            />
            <label class="form-check-label" for="choose-yes">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              id="choose-no"
              v-model="packageInfo.accessFlag"
              class="form-check-input"
              type="radio"
              name="no"
              :value="false"
              @click="onChangeAccessFlag(false)"
            />
            <label class="form-check-label" for="choose-no">No</label>
          </div>
        </div>
      </div>
    </fieldset>
    <div class="mt-3">
      <strong>Survey</strong>
    </div>
    <div class="table-responsive mt-2">
      <table class="table table-sm">
        <thead>
          <tr>
            <th class="normal-th-content">
              SN
            </th>
            <th class="normal-th-content">
              ID
            </th>
            <th class="normal-th-content">
              Country
            </th>
            <th class="normal-th-content">
              Language
            </th>
            <th class="normal-th-content">
              Access
            </th>
            <th class="normal-th-content">
              Survey Description
            </th>
            <th class="normal-th-content">
              Last Edit
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in surveyInfos" :key="item.id">
            <td>{{ index + 1 }}</td>
            <td>{{ item.snAsText }}</td>
            <td>{{ item.country.name }}</td>
            <td>{{ languages[item.languageCode] }}</td>
            <td>{{ item.access }}</td>
            <td>{{ item.description }}</td>
            <td>{{ item.updatedAt | formatDate }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="d-flex justify-content-between mt-2">
      <button
        type="buton"
        class="btn btn-outline-primary btn-cus"
        :disabled="!packageInfo.accessFlag"
        @click="add"
      >
        Add Surveys
      </button>
      <button
        type="buton"
        class="btn btn-primary btn-cus"
        :disabled="noSurvey"
        @click="save"
      >
        Save
      </button>
    </div>
  </div>
</template>
<script>
import PackageSurveyService from "../../../service/PackageSurveyService";
import PlatformService from "../../../service/PlatformService";

export default {
  name: "SurveyPackageAdd",
  props: {
    svpackage: {
      type: Object,
      default: () => {},
    },
    surveys: {
      type: Array,
      default: () => [],
    },
    countryCode: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      packageInfo: {
        id: null,
        description: null,
        survey: null,
        accessFlag: true,
        password: null,
        lensSelectorAccess: true,
      },
      originSurveys: [],
      surveyInfos: [],
      password: "",
      errors: {},
    };
  },
  computed: {
    countries() {
      return this.$store.state.common.countries;
    },
    languages() {
      return this.$store.state.common.languages;
    },
    noSurvey() {
      return (
        this.packageInfo.accessFlag &&
        (!this.surveyInfos || this.surveyInfos.length == 0)
      );
    },
  },
  watch: {
    password(newVal, oldVal) {
      if (newVal && !newVal.startsWith(this.countryCode)) {
        this.password = oldVal;
      }
    },
  },
  created() {
    this.getAllCountries();
  },
  mounted() {
    this.packageInfo = {
      ...this.svpackage,
      lensSelectorAccess: this.packageInfo.lensSelectorAccess,
    };
    this.password = this.packageInfo.password || this.countryCode;
    this.packageInfo.password = this.password;
    this.surveyInfos = this.surveys;
    this.originSurveys = this.surveyInfos;
  },
  methods: {
    getAllCountries() {
      const loader = this.$loading.show();
      PlatformService.getAll()
        .then((response) => {
          const _lensSelectorAccess =
            response.data.find((i) => i.countryCode === this.countryCode)
              ?.lensSelectorAccess ?? false;
          this.packageInfo.lensSelectorAccess = _lensSelectorAccess;
          this.packageInfo.accessFlag = _lensSelectorAccess;
          loader.hide();
        })
        .catch(this.catchError)
        .finally(() => {
          loader.hide();
        });
    },
    changePassword() {
      if (!this.password || !this.password.startsWith(this.countryCode)) {
        this.password = this.packageInfo.password;
      }
      this.packageInfo.password = this.password;
    },
    cancel() {
      this.$emit("cancel");
    },
    add() {
      this.packageInfo.password = this.password;
      this.$emit("add", {
        svpackage: this.packageInfo,
        surveys: this.surveyInfos,
      });
    },
    save() {
      if (this.invalidForm()) {
        return;
      }
      const data = {};
      data.packageInfo = { ...this.packageInfo };
      data.packageInfo.countryCode = this.countryCode;
      data.surveys = this.surveyInfos.map((survey, index) => {
        return {
          surveyId: survey.id,
          order: index,
        };
      });
      let loader = this.$loading.show();
      PackageSurveyService.create(data)
        .then(() => {
          this.$emit("save", {
            svpackage: this.packageInfo,
            surveys: this.surveyInfos,
            mode: this.mode,
          });
        })
        .catch((reason) => {
          const data = reason.response.data;
          if (data) {
            this.openToast(data.message);
          } else {
            this.openToast("Server error");
          }
        })
        .finally(() => {
          loader.hide();
        });
    },
    invalidForm() {
      this.errors = {};
      let invalid = false;
      if (!this.packageInfo.description) {
        this.errors.description = true;
        invalid = true;
      }
      if (
        !this.packageInfo.password ||
        this.packageInfo.password === this.countryCode
      ) {
        this.errors.password = true;
        invalid = true;
      }

      return invalid;
    },
    openToast(message) {
      console.log(message);
    },
    onChangeAccessFlag(accessFlag) {
      if (accessFlag) {
        this.surveyInfos = this.originSurveys;
      } else {
        this.originSurveys = this.surveyInfos;
        this.surveyInfos = [];
      }
    },
    catchError(reason) {
      const data = reason.response.data;
      console.error(data);
    },
  },
};
</script>

<style scoped>
.edit-col {
  width: 75px;
}
.edit-col .btn {
  padding-left: 0.6rem;
  padding-right: 0.6rem;
}
</style>

<style scoped>
.add {
  max-width: 1200px;
  border: 1px solid rgb(216, 219, 224);
  border-radius: 0.25rem;
  padding: 10px;
}
.btn-cus {
  width: 120px !important;
}
</style>
